/**
 * 组件版本号,组件文件地址包含此版本号
 */
export const VERSION = process.env.DSM_VERSION
// export const HOST = process.env.DSM_HOST
export const IS_HUB = process.env.DSM_IS_HUB === 'true'
export const BASEPATH = IS_HUB? process.env.DSM_BASEPATH : `a/lib/${process.env.DSM_BASEPATH}`

export const TA_APPID =
  process.env.NODE_ENV === 'production'
    ? (IS_HUB ? 'e5fd5324917e4fce8fe82f438ff48646':'1202a646b53146fa854a4915e870982b')
    : (IS_HUB ? 'debug-appid': 'bd7cd481bf884b38bdfe1c244bd04218')
export const TA_SERVERURL = IS_HUB ? 'https://tahub-grec.1y.com/sync_js' : 'https://talab-grec.1y.com/sync_js'
export const MAX_REFERRER_STRING_LENGTH = 200
export const EVENT_TYPE = {
  click: 'click',
  error: 'error',
  ajax: 'ajax',
  // ajaxFail: 'ajaxFail',
  // ajaxSuccess: 'ajaxSuccess',
  pv: 'pv',
  custom: 'custom',
  pageview: 'pageview',
}

//name, ua,page_url,duration,url_path,href,title,dsmid,eventName,res,extData,
// prevDsmid,prevName,prevEventName,nextDsmid,nextName,nextEventName,times
export const TRACK_ATTRS = [
  {
    key: 'name',
    necessary: true,
  },
  // {
  //   key: 'extData',
  // }
]

export const PLATFORM = {
  pc:'pc',
  mobile: 'mobile'
}

export const MAX_ATTR_VALUE_LENGTH = 15
// export const TA_APPIDS = {
//   SHOU_YOU_FAXING:'1202a646b53146fa854a4915e870982b'
// }

export const BIKEY = 'FnyiMv'