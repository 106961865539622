import {
  MAX_REFERRER_STRING_LENGTH,
  MAX_ATTR_VALUE_LENGTH,
  PLATFORM,
} from '../constants'

function Ya() {
  return new RegExp(
    /(.*?)\.?([^\.]*?)\.(com|net|org|biz|ws|in|me|co\.uk|co|org\.uk|ltd\.uk|plc\.uk|me\.uk|edu|mil|br\.com|cn\.com|eu\.com|hu\.com|no\.com|qc\.com|sa\.com|se\.com|se\.net|us\.com|uy\.com|ac|co\.ac|gv\.ac|or\.ac|ac\.ac|af|am|as|at|ac\.at|co\.at|gv\.at|or\.at|asn\.au|com\.au|edu\.au|org\.au|net\.au|id\.au|be|ac\.be|adm\.br|adv\.br|am\.br|arq\.br|art\.br|bio\.br|cng\.br|cnt\.br|com\.br|ecn\.br|eng\.br|esp\.br|etc\.br|eti\.br|fm\.br|fot\.br|fst\.br|g12\.br|gov\.br|ind\.br|inf\.br|jor\.br|lel\.br|med\.br|mil\.br|net\.br|nom\.br|ntr\.br|odo\.br|org\.br|ppg\.br|pro\.br|psc\.br|psi\.br|rec\.br|slg\.br|tmp\.br|tur\.br|tv\.br|vet\.br|zlg\.br|br|ab\.ca|bc\.ca|mb\.ca|nb\.ca|nf\.ca|ns\.ca|nt\.ca|on\.ca|pe\.ca|qc\.ca|sk\.ca|yk\.ca|ca|cc|ac\.cn|net\.cn|com\.cn|edu\.cn|gov\.cn|org\.cn|bj\.cn|sh\.cn|tj\.cn|cq\.cn|he\.cn|nm\.cn|ln\.cn|jl\.cn|hl\.cn|js\.cn|zj\.cn|ah\.cn|gd\.cn|gx\.cn|hi\.cn|sc\.cn|gz\.cn|yn\.cn|xz\.cn|sn\.cn|gs\.cn|qh\.cn|nx\.cn|xj\.cn|tw\.cn|hk\.cn|mo\.cn|cn|cx|cz|de|dk|fo|com\.ec|tm\.fr|com\.fr|asso\.fr|presse\.fr|fr|gf|gs|co\.il|net\.il|ac\.il|k12\.il|gov\.il|muni\.il|ac\.in|co\.in|org\.in|ernet\.in|gov\.in|net\.in|res\.in|is|it|ac\.jp|co\.jp|go\.jp|or\.jp|ne\.jp|ac\.kr|co\.kr|go\.kr|ne\.kr|nm\.kr|or\.kr|li|lt|lu|asso\.mc|tm\.mc|com\.mm|org\.mm|net\.mm|edu\.mm|gov\.mm|ms|nl|no|nu|pl|ro|org\.ro|store\.ro|tm\.ro|firm\.ro|www\.ro|arts\.ro|rec\.ro|info\.ro|nom\.ro|nt\.ro|se|si|com\.sg|org\.sg|net\.sg|gov\.sg|sk|st|tf|ac\.th|co\.th|go\.th|mi\.th|net\.th|or\.th|tm|to|com\.tr|edu\.tr|gov\.tr|k12\.tr|net\.tr|org\.tr|com\.tw|org\.tw|net\.tw|ac\.uk|uk\.com|uk\.net|gb\.com|gb\.net|vg|sh|kz|ch|info|ua|gov|name|pro|ie|hk|com\.hk|org\.hk|net\.hk|edu\.hk|us|tk|cd|by|ad|lv|eu\.lv|bz|es|jp|cl|ag|mobi|eu|co\.nz|org\.nz|net\.nz|maori\.nz|iwi\.nz|io|la|md|sc|sg|vc|tw|travel|my|se|tv|pt|com\.pt|edu\.pt|asia|fi|com\.ve|net\.ve|fi|org\.ve|web\.ve|info\.ve|co\.ve|tel|im|gr|ru|net\.ru|org\.ru|hr|com\.hr|ly|xyz)$/
  )
}

function decodeURIComponent(t) {
  var r = ''
  try {
    r = decodeURIComponent(t)
  } catch (e) {
    r = t
  }
  return r
}

function _a(e, t) {
  for (
    var r,
      n,
      i = e.charAt(0),
      o = t.split('&'),
      s = [],
      a = {},
      c = e.substring(1),
      p = 0,
      u = o.length;
    p < u;
    p++
  )
    if (
      '' !==
      (s = (s = o[p].match(/(.*?)=(.*)/)) || [o[p], o[p], ''])[1].replace(
        /\s/g,
        ''
      )
    ) {
      if (
        ((s[2] = ((n = s[2] || ''), decodeURIComponent(n.replace(/\+/g, ' ')))),
        c === s[1])
      )
        return s[2]
      ;(r = s[1].match(/(.*)\[([0-9]+)\]/))
        ? ((a[r[1]] = a[r[1]] || []), (a[r[1]][r[2]] = s[2]))
        : (a[s[1]] = s[2])
    }
  return i === e ? a : a[c]
}

function $a(e, t) {
  var r = e.charAt(0),
    t = t.split(r)
  return r === e
    ? t
    : t[(e = parseInt(e.substring(1), 10)) < 0 ? t.length + e : e - 1]
}

function urlParse(e, t) {
  var r = {}
  if ('tld?' === e) return Ya()
  if (((t = t || window.location.toString()), !e)) return t
  if (((e = e.toString()), (n = t.match(/^mailto:([^\/].+)/))))
    (r.protocol = 'mailto'), (r.email = n[1])
  else {
    if (
      ((n = (t = (n = t.match(/(.*?)\/#\!(.*)/)) ? n[1] + n[2] : t).match(
        /(.*?)#(.*)/
      )) && ((r.hash = n[2]), (t = n[1])),
      r.hash && e.match(/^#/))
    )
      return _a(e, r.hash)
    if (
      ((n = t.match(/(.*?)\?(.*)/)) && ((r.query = n[2]), (t = n[1])),
      r.query && e.match(/^\?/))
    )
      return _a(e, r.query)
    if (
      ((n = t.match(/(.*?)\:?\/\/(.*)/)) &&
        ((r.protocol = n[1].toLowerCase()), (t = n[2])),
      (n = t.match(/(.*?)(\/.*)/)) && ((r.path = n[2]), (t = n[1])),
      (r.path = (r.path || '').replace(/^([^\/])/, '/$1').replace(/\/$/, '')),
      (e = e.match(/^[\-0-9]+$/) ? e.replace(/^([^\/])/, '/$1') : e).match(
        /^\//
      ))
    )
      return $a(e, r.path.substring(1))
    if (
      ((n = (n = $a('/-1', r.path.substring(1))) && n.match(/(.*?)\.(.*)/)) &&
        ((r.file = n[0]), (r.filename = n[1]), (r.fileext = n[2])),
      (n = t.match(/(.*)\:([0-9]+)$/)) && ((r.port = n[2]), (t = n[1])),
      (n = t.match(/(.*?)@(.*)/)) && ((r.auth = n[1]), (t = n[2])),
      r.auth &&
        ((n = r.auth.match(/(.*)\:(.*)/)),
        (r.user = n ? n[1] : r.auth),
        (r.pass = n ? n[2] : void 0)),
      (r.hostname = t.toLowerCase()),
      '.' === e.charAt(0))
    )
      return $a(e, r.hostname)
    Ya() &&
      (n = r.hostname.match(Ya())) &&
      ((r.tld = n[3]),
      (r.domain = n[2] ? n[2] + '.' + n[3] : void 0),
      (r.sub = n[1] || void 0))
    var n = r.port ? ':' + r.port : ''
    ;(r.protocol = r.protocol || window.location.protocol.replace(':', '')),
      (r.port = r.port || ('https' === r.protocol ? '443' : '80')),
      (r.protocol = r.protocol || ('443' === r.port ? 'https' : 'http')),
      (r.basic = r.protocol + '://' + r.hostname + n)
  }
  return e in r ? r[e] : '{}' === e ? r : ''
}

export const formatDate = function (e) {
  let r
  function t(e) {
    return e < 10 ? '0' + e : e
  }
  return (
    e.getFullYear() +
    '-' +
    t(e.getMonth() + 1) +
    '-' +
    t(e.getDate()) +
    ' ' +
    t(e.getHours()) +
    ':' +
    t(e.getMinutes()) +
    ':' +
    t(e.getSeconds()) +
    '.' +
    ((r = e.getMilliseconds()) < 10 ? '00' + r : r < 100 ? '0' + r : r)
  )
}

export const UUIDv4 = function () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (e) {
    var t = (16 * Math.random()) | 0
    return ('x' == e ? t : (3 & t) | 8).toString(16)
  })
}

export const stripEmptyProperties = function (obj) {
  return obj
}

export const getReferrer = function (e) {
  e = e || document.referrer
  return 'string' != typeof e
    ? '取值异常_referrer异常_' + String(e)
    : 'string' ==
      typeof (e = (e =
        0 === e.indexOf('https://www.baidu.com/') ? e.split('?')[0] : e).slice(
        0,
        MAX_REFERRER_STRING_LENGTH
      ))
    ? e
    : ''
}

export const getPageProperties = function () {
  var e = getReferrer()
  return stripEmptyProperties({
    referrer: e,
    referrer_host: e && urlParse('hostname', e),
    name: document.title,
  })
}

/**
 * 获取对象的简要值,如果某个字段内容过长,则将该字段值改为:"内容过长",嵌套对象最多只递归到第三层
 * @param {json对象} obj
 */
export const getShortData = function (obj, level = 1) {
  if (isObject(obj)) {
    if (level > 3) {
      return '>oo'
    } else {
      const keys = Object.keys(obj)
      const resData = {}
      keys.forEach((key) => {
        resData[key] = getShortData(obj[key], level + 1)
      })
      return resData
    }
  } else if (isArray(obj)) {
    // const resData = []
    // obj.forEach(n=>{
    //   resData.push(getShortData(n,level+1))
    // })
    return `arr=${obj.length}`
  } else if (isString(obj)) {
    return obj.slice(0, MAX_ATTR_VALUE_LENGTH)
  } else {
    return obj
  }
}

export const isObject = function (obj) {
  return '[object Object]' === Object.prototype.toString.call(obj) && null !== obj
}

export const isArray = function (obj) {
  return '[object Array]' === Object.prototype.toString.call(obj)
}

export const isString = function (e) {
  return '[object String]' === Object.prototype.toString.call(e)
}

export const clone = function (obj) {
  return JSON.parse(JSON.stringify(obj))
}

export const isFunction = (val) => val && typeof val === 'function'

export const getPlatform = function () {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
    ? PLATFORM.mobile
    : PLATFORM.pc
}


export const getXpath = function (tar) {
  let _path = []
  let tarItem = tar
  //_bodyHTML || getBodyHtml();
  if (tarItem && tarItem.nodeType == 1) {
    _path.push(getAttr(tar))
    for (tarItem; tarItem; ) {
      tarItem = tarItem.parentNode
      var _xpath = getAttr(tarItem)

      _xpath && _path.unshift(_xpath)
      if (
        tarItem.tagName.toLowerCase() === 'body' ||
        (_xpath && !!~_xpath.indexOf('#'))
      ) {
        break
      }
    }
  }
  return _path.length > 0 ? _path.join('>') : null
}

export const getAttr = function (tar) {
  if (tar && tar.tagName) {
    let _class = tar.className
    let _id = tar.id
    let _path = tar.tagName.toLowerCase()
    if (_id) {
      _path += '#' + _id
    } else if (_class) {
      // 移除模块监控class
      _class = _class
        .replace(/className.{10,}?( |$)/g, '')
        .replace(/(^\s*)|(\s*$)/g, '')
        .replace(/ +/g, ' ')
        .replace(/ /g, '.')
      if (_class != '') {
        _path += '.' + _class
      }
    }
    return _path
  } else {
    return false
  }
}
